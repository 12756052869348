import _ from 'lodash';
import moment from 'moment';

const numOfSecondsPerOneHour = 60 * 60;
const numOfSecondsPerOneDay = 60 * 60 * 24;
const numOfSecondsPerOneWeek = numOfSecondsPerOneDay * 7;
const numOfSecondsPerOneMonth = numOfSecondsPerOneDay * 30;

export const dateTimeHelper = {
  convertDate(date) {
    if (!date) {
      return date;
    }

    const result = moment(date).toDate();

    return result;
  },
  adjustToDate(toDate) {
    if (!toDate) {
      return toDate;
    }

    const result = moment(toDate).add(1, 'days').toDate();

    return result;
  },
  startOfDate(date) {
    if (!date) {
      return date;
    }

    const result = moment(date).startOf('date').toDate();

    return result;
  },
  convertToDuration(seconds, duration) {
    const result = 0;

    switch (duration) {
      case 'hours':
        return seconds / numOfSecondsPerOneHour;

      case 'days':
        return seconds / numOfSecondsPerOneDay;

      case 'weeks':
        return seconds / numOfSecondsPerOneWeek;

      case 'months':
        return seconds / numOfSecondsPerOneMonth;
    }

    return result;
  },
  convertToSeconds(amount, duration) {
    switch (duration) {
      case 'hours':
        return amount * numOfSecondsPerOneHour;

      case 'days':
        return amount * numOfSecondsPerOneDay;

      case 'weeks':
        return amount * numOfSecondsPerOneWeek;

      case 'months':
        return amount * numOfSecondsPerOneMonth;
    }

    return 0;
  },
  startOfMonth(date) {
    if (!date) {
      return date;
    }
    const result = moment(date).startOf('month').format('YYYY-MM-DD HH:mm:ss');
    return result;
  },
  endOfMonth(date) {
    if (!date) {
      return date;
    }
    const result = moment(date).endOf('month').format('YYYY-MM-DD HH:mm:ss');
    return result;
  },
  lastMonthWithCutOff(timestamp, date) {
    if (!timestamp) {
      return timestamp;
    }
    const option = {
      year: timestamp.year(),
      month: timestamp.month(),
      date: 26,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    };
    const result = moment().utcOffset(0).set(option).subtract(1, 'months').toDate();
    return result;
  },
  currentMonthWithCutOff(timestamp, date) {
    if (!timestamp) {
      return timestamp;
    }
    const option = {
      year: timestamp.year(),
      month: timestamp.month(),
      date: 26,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    };
    const result = moment().utcOffset(0).set(option).toDate();
    return result;
  }  
};

export default dateTimeHelper;
