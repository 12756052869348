import router from '@/router';
import Vue from 'vue';
import { endpoints, RouterName, env } from '@/constants';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import {
  SETTINGS_UPDATE_LOCALE,
} from '@/store/actions/setting';

const state = {
  locale: env.app.locale
};

const getters = {
  locale: state => state.locale,
};

const mutations = {
  [SETTINGS_UPDATE_LOCALE]: (state, locale) => {
    state.locale = locale;
    window.localStorage.setItem('locale', locale);
  },
};

export default {
  state,
  getters,
  mutations,
};
