import router from "@/router";
import Vue from "vue";
import endpoints from "@/constants/endpoints";
import http from "@/utils/http";
import responseHelper from '@/utils/response-helper';
import {
  UPDATE_PARTNER,
  GET_SELECTED_PARTNER,
  CREATE_PARTNER
} from "../actions/partner";

const state = {
  partnerSelected: {}
};

const actions = {
  async getSelectedPartner({ commit }, partner) {
    try {
      commit(GET_SELECTED_PARTNER, partner);
    } catch (err) {
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },

  async updatePartner({ commit }, partner) {
    const updatePartner = partner;
    try {
      console.log(updatePartner.status);

      const data = {
        name: updatePartner.name,
        partner_type: updatePartner.partner_type
      };
      let res = await http.put(endpoints.updatePartner(updatePartner.id), data);
      let partner = res.data;
      console.log(partner);
      commit(UPDATE_PARTNER, partner);
      Vue.notify({
        group: "notifications",
        type: "success",
        title: "Success",
        text: "Partner has been updated"
      });
      router.push("/admin/childpool");
    } catch (err) {
      console.log(err);
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },

   

    async createPartner({commit},partner){
        const newPartner = partner;
        
        try {
            console.log(newPartner)
                const data = {
                    email: newPartner.email,
                    name: newPartner.name,
                    partner_type: newPartner.partner_type
                };
                let res = await http.post(endpoints.createPartner, data);
                let partner = res.data
                console.log(partner)
                commit( CREATE_PARTNER, partner)

                Vue.notify({
                    group: "notifications",
                    type: "success",
                    title: "Success",
                    text: ' New partner has been created'
                });
                router.push("/admin/childpool");
            }
            catch (err) {
                console.log(err)
                Vue.notify({
                    group: "notifications",
                    type: "error",
                    title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                    text: responseHelper.getErrorMessage(err)
                });
            }
    }
  }


const mutations = {
  [GET_SELECTED_PARTNER]: (state, partner) => (state.partnerSelected = partner),
  [UPDATE_PARTNER]: (state, partner) => (state.partnerSelected = partner)
};
export default {
  state,
  actions,
  mutations
};
