import _ from 'lodash';

const NODE_ENV = process.env.NODE_ENV || 'development';
const isProduction = Boolean(process.env.PRODUCTION === 'true');

export const env = {
  node: process.env.NODE_ENV,
  envName: process.env.VUE_APP_ENV_NAME,
  isProduction,
  isTest: process.env.NODE_ENV === 'test',
  isDevelopment: process.env.NODE_ENV === 'development',
  app: {
    name: process.env.VUE_APP_NAME || 'masterpool-app',
    apiUrl: process.env.VUE_APP_BASE_URL || '',
    version: process.env.VUE_APP_VERSION || '',
    description: process.env.VUE_APP_PACKAGE_DESCRIPTION || '',
    buildDate: process.env.VUE_APP_BUILD_DATE || '',
    buildNumber: process.env.VUE_APP_BUILD_NUMBER || '',
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  },
  recaptcha: {
    key: process.env.VUE_APP_SITEKEY || '6Lc8fdIdAAAAANiE88gEmj_YEY1NOFeJxJ4guZb5',
    isDisabled: process.env.VUE_APP_IS_DISABLED_CAPTCHA == 'true',
  },
  theme: {
    name: _.trim(process.env.VUE_APP_THEME_NAME || '').toLowerCase(),
    moonstake: {
      name: process.env.VUE_APP_THEME_MOONSTAKE_NAME,
      title: process.env.VUE_APP_THEME_MOONSTAKE_TITLE,
    },
    binarystar: {
      name: process.env.VUE_APP_THEME_BINARYSTAR_NAME,
      title: process.env.VUE_APP_THEME_BINARYSTAR_TITLE,
    },
  },
};

console.log('=======================================================');
console.log(` ${env.app.name} version: ${env.app.version}. Build #${env.app.buildNumber} ${env.app.description}, date: ${env.app.buildDate}`);
console.log('=======================================================');
console.log('%c%s', 'color: white; background: red; font-size: 24px;', '  STOP!   ');
console.log('This browser feature is intended for developers.');

