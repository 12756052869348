import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import responseHelper from '@/utils/response-helper';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');

const Charts = () => import('@/views/charts/Charts');
const Widgets = () => import('@/views/widgets/Widgets');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const SetPassword = () => import('@/views/pages/SetPassword');
const ForgotPassword = () => import('@/views/pages/ForgotPassword');
const Register = () => import('@/views/pages/Register');
const ActiveUser = () => import('@/views/pages/ActiveUser')

// Users
const Users = () => import('@/views/users/Users');
const User = () => import('@/views/users/User');

// Staking platform
const ListStakingPlatform = () =>
  import('@/views/pages/admin/stakingPlatform/ListPlatform');
const DetailStakingPlatform = () =>
  import('@/views/pages/admin/stakingPlatform/DetailPlatform');
const DetailPlatformContract = () =>
  import('@/views/pages/admin/stakingPlatform/DetailPlatformContract');
const EditPlan = () => import('@/views/pages/admin/stakingPlatform/EditPlan');
const PayoutConfig = () =>
  import('@/views/pages/admin/stakingPlatform/PayoutConfig');
const EditNative = () =>
  import('@/views/pages/admin/stakingPlatform/EditNative');
const EditERC20 = () =>
  import('@/views/pages/admin/stakingPlatform/ERC20/EditERC20');
const AddERC20 = () =>
  import('@/views/pages/admin/stakingPlatform/ERC20/AddERC20');
const EditPayoutMax = () =>
  import('@/views/pages/admin/stakingPlatform/ERC20/EditPayoutMax');
const AddPlan = () =>
  import('@/views/pages/admin/stakingPlatform/ERC20/AddPlan');
const EditStakingPlan = () =>
  import('@/views/pages/admin/stakingPlatform/ERC20/EditStakingPlan');
// Childpool
const ChildPoolList = () =>
  import('@/views/pages/admin/childPool/ChildPoolList');
const EditPool = () => import('@/views/pages/admin/childPool/EditPool');

// Childpool - Config
const ApiKeyManagement = () =>
  import('@/views/pages/admin/childPool/ApiKeyManagement');
const GrandChildPool = () =>
  import('@/views/pages/admin/childPool/GrandChildPool');
const TransactionMemo = () =>
  import('@/views/pages/admin/childPool/TransactionMemo');
const Commission = () => import('@/views/pages/admin/childPool/Commission');

// User manament
const ListUser = () => import('@/views/pages/admin/userAccount/ListUser');
const EditUser = () => import('@/views/pages/admin/userAccount/EditUser');

const DetailPool = () => import('@/views/pages/admin/childPool/DetailPool');
// Profile
const Profile = () => import('@/views/pages/admin/profile/Profile');
const ChangePassword = () =>
  import('@/views/pages/admin/profile/ChangePassword');
const Setting2FA = () => import('@/views/pages/admin/profile/Setting2FA');
const LoginHistory = () => import('@/views/pages/admin/profile/LoginHistory');

// Setting
const StakingReward = () => import('@/views/pages/admin/setting/StakingReward');
const DistributeCommission = () =>
  import('@/views/pages/admin/setting/DistributeCommission');
const ColdWallet = () => import('@/views/pages/admin/setting/ColdWallet');

// Payout
const ListPayout = () => import('@/views/pages/admin/payout/ListPayout');

// Roles
const ListRoles = () => import('@/views/pages/admin/roles/ListRoles');
const EditRoles = () => import('@/views/pages/admin/roles/EditRoles');
const AddRoles = () => import('@/views/pages/admin/roles/EditRoles');

// Reward
const ListReward = () => import('@/views/pages/admin/reward/ListReward');

// Revenue
const ListRevenue = () => import('@/views/pages/admin/revenue/ListRevenue');
Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

function configRoutes() {
  return [
    {
      path: '/admin',
      redirect: () => {
        const userPermission = store.getters.userPermission || [];
        let canViewStakingPlatform = false;
        let canViewChildPartnerList = false;
        let canViewUserList = false;
        userPermission.forEach((item) => {
          if (item.name === 'VIEW_LIST_STAKING_PLATFORM') {
            canViewStakingPlatform = true;
          }

          if (item.name === 'VIEW_LIST_CHILD_PARTNER') {
            canViewChildPartnerList = true;
          }

          if (item.name === 'VIEW_LIST_USER') {
            canViewUserList = true;
          }
        });

        if (canViewStakingPlatform) {
          return '/admin/staking-platform';
        }

        if (canViewChildPartnerList) {
          return '/admin/childpool';
        }

        if (canViewUserList) {
          return '/admin/user-management';
        }

        return '/admin/staking-platform';
      },
      name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_HOME'),
      component: TheContainer,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'dashboard',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_DASHBOARD'),
          component: Dashboard
        },
        /** **** Setting start **************/
        {
          path: 'setting/staking-reward',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_REWARD'),
          component: StakingReward
        },
        {
          path: 'setting/distribute-commission',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_DISTRIBUTE_COMMISSION'),
          component: DistributeCommission
        },
        {
          path: 'setting/cold-wallet',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_COLD_WALLET'),
          component: ColdWallet
        },
        /** **** Setting end **************/
        /** **** staking platform group START ******/
        {
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM'),
          path: 'staking-platform',
          redirect: 'staking-platform',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ListStakingPlatform
            },
            {
              path: ':platform/detail',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_DETAIL'),
              component: DetailStakingPlatform
            },
            {
              path: 'edit-native/:id',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_EDIT_NATIVE'),
              component: EditNative
            },
            {
              path: 'erc20/edit-erc20/:id',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_EDIT_ERC20'),
              component: EditERC20
            },
            {
              path: 'erc20/add-erc20',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_ADD_ERC20'),
              component: AddERC20
            },
            {
              path: 'erc20/:id/add-plan',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_ADD_STAKING_PLAN'),
              component: AddPlan
            },
            {
              path: 'erc20/edit-staking-plan/:staking_id/plans/:id',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_EDIT_STAKING_PLAN'),
              component: EditStakingPlan
            },
            {
              path: 'erc20/edit-payout-max/:staking_id/payouts/:id',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_EDIT_PAYOUT_MAX'),
              component: EditPayoutMax
            },
            {
              path: ':platform/contract',
              redirect: ':platform/contract',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_CONTRACT'),
              component: {
                render(c) {
                  return c('router-view');
                }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: DetailPlatformContract
                },
                {
                  path: 'edit-plan',
                  name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_EDIT_PLAN'),
                  component: EditPlan
                },
                {
                  path: 'payout-configuration',
                  name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_PLATFORM_PAYOUT_CONFIGURATION'),
                  component: PayoutConfig
                }
              ]
            }
          ]
        },
        /** **** staking platform group END ******/
        /** **** Payout group START ******/
        {
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PAYOUT'),
          path: 'payout',
          redirect: 'payout',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ListPayout
            },
          ]
        },
        /** **** payout group END ******/
        /** **** child pool group START ******/
        {
          path: 'childpool',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL'),
          redirect: 'childpool',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ChildPoolList
            },
            {
              path: 'create',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_CREATE'),
              component: EditPool
            },
            {
              path: 'edit/:id',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_EDIT'),
              component: EditPool
            },
            {
              path: 'detail/:id',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_DETAIL'),
              redirect: 'detail/:id',
              component: {
                render(c) {
                  return c('router-view');
                }
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: DetailPool
                },
                // ****** child pool Config start ******
                {
                  path: 'api-key',
                  name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_DETAIL_API_KEY_MANAGEMENT'),
                  component: ApiKeyManagement
                },
                {
                  path: 'grand-child',
                  name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_DETAIL_GRAND_CHILD'),
                  component: GrandChildPool
                },
                {
                  path: 'transaction-memo',
                  name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_DETAIL_TRANSACTION_MEMO'),
                  component: TransactionMemo
                },
                {
                  path: 'commission',
                  name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL_DETAIL_COMMISSION'),
                  component: Commission
                }
                // ****** child pool Config end ******
              ]
            }
          ]
        },
        /** **** child pool group END ******/

        /** **** User manament group START ******/
        {
          path: 'user-management',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_USER_MANAGEMENT'),
          redirect: 'user-management',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ListUser
            },
            {
              path: 'create-user',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_USER_MANAGEMENT_CREATE_USER'),
              component: EditUser
            },
            {
              path: 'edit-user/:userId',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_USER_MANAGEMENT_EDIT_USER'),
              props: true,
              component: EditUser
            }
          ]
        },
        /** **** User manament group END ******/

        /** **** profile group START ******/
        {
          path: 'profile',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PROFILE'),
          redirect: 'profile',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: Profile
            },
            {
              path: 'change-password',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PROFILE_CHANGE_PASSWORD'),
              component: ChangePassword
            },
            {
              path: 'setting-2fa',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PROFILE_SETTING_2FA'),
              component: Setting2FA
            },
            {
              path: 'login-history',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PROFILE_LOGIN_HISTORY'),
              component: LoginHistory
            }
          ]
        },
        /** **** profile group END ******/
        /**  Roles Start  **/
        {
          path: 'roles',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_ROLES'),
          redirect: 'roles',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ListRoles
            },
            {
              path: 'add-roles',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_ROLES_ADD_ROLES'),
              component: AddRoles,
            },
            {
              path: 'edit-roles/:roleId',
              name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_ROLES_EDIT_ROLES'),
              component: EditRoles,
            }
          ]
        },
        /**  Roles eng  **/
        {
          path: 'reward',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_TOTAL_REWARD'),
          },
          redirect: 'reward',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ListReward,
            },
          ]
        },
        {
          path: 'revenue',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_REVENUE'),
          },
          redirect: 'revenue',
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ListRevenue,
            },
          ]
        },
      ]
    },
    {
      path: '/',
      redirect: '/login',
      name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PAGES'),
      component: {
        render(c) {
          return c('router-view');
        }
      },
      children: [
        {
          path: '404',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PAGE_404'),
          component: Page404,
        },
        {
          path: '500',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_PAGE_500'),
          component: Page500
        },
        {
          path: 'login',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_LOGIN'),
          component: Login,
        },
        {
          path: 'forgot-password',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_FORGOT_PASSWORD'),
          name: 'Forgot Password',
          component: ForgotPassword,
        },
        {
          path: 'set-new-password',
          props: true,
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SET_PASSWORD'),
          name: 'Set Password',
          component: SetPassword,
        },
        {
          path: 'active-user',
          props: true,
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_ACTIVE_USER'),
          name: 'Active User',
          component: ActiveUser
        }
        ,
        {
          path: 'register',
          name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_REGISTER'),
          component: Register,
        }
      ]
    },
    { path: '*', component: Page404 }
  ];
}
