import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import babelPolyfill from 'babel-polyfill';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import dotenv from 'dotenv';

dotenv.config();

import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import Notifications from 'vue-notification';
// import { extend } from "vee-validate";
// import { required, email, confirmed } from "vee-validate/dist/rules";
import { extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import VueProgressBar from 'vue-progressbar';
import VueCurrencyInput from 'vue-currency-input';
import Moment from 'vue-moment';
import http from './utils/http';
import i18n from '@/i18n';

import { VueReCaptcha } from 'vue-recaptcha-v3';
import { env } from '@/constants';

Vue.use(VueReCaptcha, {
  siteKey: env.recaptcha.key,
});

Vue.prototype.$http = http;
const pluginOptions = {
  globalOptions: { precision: 3, currency: null }
};
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(Moment);

Vue.use(CoreuiVue);
Vue.use(Notifications);
Vue.use(VueProgressBar, {
  color: '#321fdb',
  failedColor: 'red',
  height: '2px'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Vee-Validate
// extend("required", required);
// extend("email", email);

// extend("confirmed",confirmed);

extend('rulesMinPassword', {
  message: field => 'Password length must be equals or greater than 6',
  validate: value => value.length >= 6
});

extend('checkTokenAddress', {
  message: field => 'Wrong Token Address',
  // eslint-disable-next-line no-undef
  validate: value => web.utils.isAddress(value)
});
// END

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // message: messages[rule] // assign message
  });
});

configure({
  defaultMessage: (field, values) => {
    // console.log(field, values);
    // override the field name.
    let text = i18n.t(field);
    if (text !== field) {
      values._field_ = text;
    } else {
      const key = `validate_filed.${field}`;
      text = i18n.t(key);
      values._field_ = text !== key ? text : field;
    }

    return i18n.t(`validate_message.${values._rule_}`, values);
  }
});

new Vue({
  el: '#app',
  router,
  babelPolyfill, // Must have this to use async await
  icons,
  store,
  i18n,
  template: '<App/>',
  components: {
    App
  }
});
