import router from "@/router";
import Vue from 'vue';
import endpoints from '@/constants/endpoints';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import {
    UPDATE_ROLE,
    GET_SELECTED_ROLE,
    CREATE_ROLE,
    GET_ACTION
} from '../actions/role';

const state = {
    roleSelected: {},
    action: '',
    checkerr: '',
};

const actions = {
    async getSelectedRole({ commit }, role){
        try {
            commit(GET_SELECTED_ROLE, role);
        } catch (err) {
            Vue.notify({
                group: 'notifications',
                type: 'error',
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            })
        }
    },
    async AddRoles({ commit }, role) {
        try {
            let data = {
                name: role.name,
                level: role.level,
                permission_ids: role.permission_ids
            }
            let res = await http.post(endpoints.addRoles, data);
            if (res.data) {
                return res.data
            }
        } catch (err) {
            let errmessage;
            if(err.response.data &&  err.response.data.code && err.response.data.code.details.length){
                errmessage = err.response.data.code.details[0].message
                this.checkerr = errmessage
            }
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    getAction({ commit }, action) {
        // console.log("action role", action);
        commit(GET_ACTION, action);
    }
};
const mutations = {
    [GET_SELECTED_ROLE]: (state, role) => (state.roleSelected = role),
    [UPDATE_ROLE]: (state, role) => (state.roleSelected = role),
    [GET_ACTION]: (state, action) => (state.action = action)
};
export default {
    state,
    actions,
    mutations,
};