import axios from 'axios';
import _ from 'lodash';
import qs from 'query-string';
import store from '../store';
import Vue from 'vue';
import { AUTH_LOGOUT } from '../store/actions/auth';
import { loginHelper, responseHelper } from '@/utils';
import router from '@/router';

export const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
  withCredentials: true
});

// Add a request interceptor
http.interceptors.request.use(
  config => {
    const request = config;
    store.dispatch('setLoading', true);
    return config;
  },
  error => Promise.reject(error)
);

const goToLoginPage = _.debounce(() => {
  store.dispatch(AUTH_LOGOUT, true);
  Vue.notify({
    group: 'notifications',
    type: 'error',
    title: 'Error',
    text: responseHelper.getMessageByCode('UNAUTHORIZED'),
  });

  const url = loginHelper.getLoginUrl();
  router.push(url);
}, 2000);


// Add a response interceptor
http.interceptors.response.use(
  response => {
    // Return JSON data
    store.dispatch('setLoading', false);
    if (response.data) {
      return response.data;
    }
    return response;
  },
  error => {
    // Attempt to get the actual error returned from API
    const err = (error.response && error.response.data) || error;
    store.dispatch('setLoading', false);

    if (error.response && (error.response.status === 401)) {
      if (error.response.data && error.response.data.code === 'LOGIN_FAIL') {
        // Vue.notify({
        //   group: 'notifications',
        //   type: 'error',
        //   title: 'Error',
        //   text: responseHelper.getErrorMessage(err),
        // });
      } else {
        goToLoginPage();
      }
    }

    if (error.response && error.response.status) {
      err.status = error.response.status;
    }

    return Promise.reject(err);
  }
);

export default http;
