import router from "@/router";
import Vue from "vue";
import endpoints from "@/constants/endpoints";
import http from "@/utils/http";
import responseHelper from '@/utils/response-helper';
import {
    GET_SELECTED_PLATFORM,
    GET_SELECTED_PAYOUT,
    UPDATE_PLATFORM,
    GET_SELECTED_PLAN,
    FETCH_TIME_UNIT

} from "../actions/platform";
const state = {
    selectedPlatform: {},
    selectedPayout:{},
    selectedPlan:{},
    timeUnits: [],
    checkExpired: false,
};

const actions = {
    async getSelectedPlatform({ commit }, platform) {
        try {
            commit(GET_SELECTED_PLATFORM, platform);
        } catch (err) {
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async getSelectedPayout({ commit }, platform) {
        try {
            commit(GET_SELECTED_PAYOUT, platform);
        } catch (err) {
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async getSelectedPlan({ commit }, platform) {
        try {
            commit(GET_SELECTED_PLAN, platform);
        } catch (err) {
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async updateStakingPlatform({ commit }, platform) {
        let data;
        let formData = new FormData();
        try {
            if (platform.staking_type == 'NATIVE') {




                data = {
                    name: platform.name,
                    symbol: platform.symbol,
                    icon: platform.icon,
                    order_index: platform.order_index,
                    estimate_earn_per_year: platform.estimate_earn_per_year,
                    lockup_unvote: platform.lockup_unvote,
                    lockup_unvote_unit: platform.lockup_unvote_unit,
                    status: platform.status,
                    confirmation_block: platform.confirmation_block,
                    validator_address: platform.validator_address,
                    staking_type: 'NATIVE'
                }

            } else {
                if (platform.staking_type == 'CONTRACT') {
                    data = {
                        name: platform.name,
                        symbol: platform.symbol,
                        icon: platform.icon,
                        order_index: platform.order_index,
                        estimate_earn_per_year: platform.estimate_earn_per_year,
                        lockup_unvote: platform.lockup_unvote,
                        lockup_unvote_unit: platform.lockup_unvote_unit,
                        payout_reward: platform.payout_reward,
                        payout_reward_unit: platform.payout_reward_unit,
                        sc_lookup_addr: platform.sc_lookup_addr,
                        sc_token_address: platform.sc_token_address,
                        status: platform.status,
                        confirmation_block: platform.confirmation_block,
                        validator_address: platform.validator_address,
                        staking_type: 'CONTRACT'
                    }

                }
            }
            for (let key in data) {
                if (data[key] == null) continue;
                formData.append(key, data[key]);
            }
            
            let res = await http.put(endpoints.updateStakingPlatform(platform.id), formData);
            if (res.data) {
                return res.data
            }

        } catch (err) {
            console.log(err);
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async updateERC20({ commit }, platform) {
        let data;
        let formData = new FormData();
        try {
            data = {
                name: platform.name,
                symbol: platform.symbol,
                icon: platform.icon,
                erc20_validator_fee: platform.erc20_validator_fee,
                erc20_reward_estimate: platform.erc20_reward_estimate,
                erc20_duration: platform.erc20_duration,
                status: platform.status,
                decimal: platform.decimal

            }
            for (let key in data) {
                if (data[key] == null) continue;
                formData.append(key, data[key]);
            }
            let res = await http.put(endpoints.updateerc20(platform.id),formData);
            if (res.data) {
                return res.data
            }

        } catch (err) {
            console.log(err);
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async updateNative({ commit}, nativePlatfotm){
        console.log(nativePlatfotm);
        let data;
        let formData = new FormData();
        try {
            data = {
                name: nativePlatfotm.name,
                symbol: nativePlatfotm.symbol,
                icon: nativePlatfotm.icon,
                description: nativePlatfotm.description,
                order_index: nativePlatfotm.order_index,
                estimate_earn_per_year: nativePlatfotm.estimate_earn_per_year,
                estimate_earn_range_per_year: nativePlatfotm.estimate_earn_range_per_year,
                lockup_unvote: nativePlatfotm.lockup_unvote,
                lockup_unvote_unit: nativePlatfotm.lockup_unvote_unit,
                payout_reward: nativePlatfotm.payout_reward,
                payout_reward_unit: nativePlatfotm.payout_reward_unit,
                status: nativePlatfotm.status,
                confirmation_block: nativePlatfotm.confirmation_block,
                staking_type: nativePlatfotm.staking_type,
                sc_lookup_addr: nativePlatfotm.sc_lookup_addr,
                sc_token_address: nativePlatfotm.sc_token_address,
                validator_address: nativePlatfotm.validator_address,
            }
            for( let key in data ) {
                if (data[key] == null) continue;
                formData.append(key, data[key]);
            }
            let res = await http.put(endpoints.updatenative(nativePlatfotm.id), formData)
            if (res.data) {
                return res.data
            }
        } catch (err) {
            Vue.notify({
                group: 'notifications',
                type: 'error',
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async fetchTimeUnit({ commit }) {
        try {

            let res = await http.get(endpoints.getStakingPlatformTimeUnit);
            if (res.data) {
                commit(FETCH_TIME_UNIT, res.data)
            }


        } catch (err) {
            console.log(err);
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async AddERC20({ commit }, platform) {
        let data;
        let formData = new FormData();
        try {
            data = {
                platform: platform.platform,
                name: platform.name,
                symbol: platform.symbol,
                icon: platform.icon,
                sc_token_address: platform.sc_token_address,
                erc20_validator_fee: platform.erc20_validator_fee,
                erc20_reward_estimate: platform.erc20_reward_estimate,
                erc20_duration: platform.erc20_duration,
                status: platform.status,
                max_payout: platform.max_payout,
                decimal: platform.decimal
            }
            for (let key in data) {
                if (data[key] == null) continue;
                formData.append(key, data[key]);
            }
            let res = await http.post(endpoints.addERC20, formData);
            if (res.data) {
                return res.data
            }

        } catch (err) {
            console.log(err);
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },
    async AddStakingPlan({ commit }, plan) {
        try {
            let data = {
                name: plan.name,
                duration: plan.duration,
                duration_type: plan.duration_type,
                reward_percentage: Number(plan.reward_percentage),
                status: plan.status,

            }
            let res = await http.post(endpoints.AddStakingPlan(plan.staking_platform_id), data);
            if (res.data) {
                return res.data
            }

        } catch (err) {
            console.log(err);
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
    },

};
const mutations = {
    [GET_SELECTED_PLATFORM]: (state, platform) => (state.selectedPlatform = platform),
    [GET_SELECTED_PAYOUT]: (state, platform) => (state.selectedPayout = platform),
    [GET_SELECTED_PLAN]: (state, plan) => (state.selectedPlan = plan),
    [FETCH_TIME_UNIT]: (state, units) => (state.timeUnits = units)

};
export default {
    state,
    actions,
    mutations
};
