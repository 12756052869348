const actions = {
  setLoading: ({ commit }, payload) => {
    commit("SET_LOADING", payload);
  }
};

const getters = {
  loading: state => state.loading
};

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  }
};

const state = {
  loading: false
};

export default {
  state,
  actions,
  getters,
  mutations
};
