import router from "@/router";
import Vue from "vue";
import endpoints from "@/constants/endpoints";
import http from "@/utils/http";
import responseHelper from '@/utils/response-helper';
import {
  UPDATE_USER,
  GET_SELECTED_USER,
  CREATE_USER,
  GET_ACTION
} from "../actions/user";
const state = {
  userSelected: {},
  action: "",
  checkerr: '',
};

const actions = {
  async getSelectedUser({ commit }, user) {
    try {
      console.log(user);
      commit(GET_SELECTED_USER, user);
    } catch (err) {
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },
  async updateUser({ commit }, user) {
    const updateUser = user;
    try {
      const data = {
        user_sts: updateUser.status,
        name: updateUser.name,
        role_id: updateUser.role_id
      };
      let res = await http.put(endpoints.updateUser(updateUser.id), data);
      let user = res.data;
      console.log(user);
      commit(UPDATE_USER, user);
      Vue.notify({
        group: "notifications",
        type: "success",
        title: "Success",
        text: "User has been updated"
      });
      router.push("/admin/user-management");
    } catch (err) {
      console.log(err);
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },
  async createUser({ commit }, user) {
    const newUser = user;

    try {
      console.log(newUser);
      const data = {
        email: newUser.email,
        name: newUser.name,
        role_id: newUser.role_id
      };
      let res = await http.post(endpoints.createUser, data);
      let user = res.data;
      console.log(user);
      Vue.notify({
        group: "notifications",
        type: "success",
        title: "Success",
        text: "New user has been created"
      });
      router.push("/admin/user-management");
    } catch (err) {
      console.log(err);
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },
  async reSendActiveCode({ commit }, id) {
    try {
      let res = await http.post(endpoints.reSendActiveCode(id));
      if (res.data) {
        Vue.notify({
          group: "notifications",
          type: "success",
          title: "Success",
          text: " New active code has been sent to your email"
        });
      }
    } catch (err) {
      console.log(err);
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },
  getAction({ commit }, action) {
    commit(GET_ACTION, action);
  },
  async reSendOtp({ commit }, payload) {
        try {
            let data = {
                verify_token: payload.verify_token,
            }
            let res = await http.post(endpoints.resendOtp, data);
            Vue.notify({
                group: 'notifications',
                type: 'success',
                text: 'Success please check your email'
            });
        } catch (err) {
            let errmessage;
            if(err.response.data &&  err.response.data.code && err.response.data.code.details.length){
                errmessage = err.response.data.code.details[0].message
                this.checkerr = errmessage
            }
            Vue.notify({
                group: "notifications",
                type: "error",
                title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
                text: responseHelper.getErrorMessage(err)
            });
        }
  },
};
const mutations = {
  [GET_SELECTED_USER]: (state, user) => (state.userSelected = user),
  [UPDATE_USER]: (state, user) => (state.userSelected = user),
  [GET_ACTION]: (state, action) => (state.action = action)
};
export default {
  state,
  actions,
  mutations
};
