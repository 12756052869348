import router from "@/router";
import Vue from "vue";
import endpoints from "@/constants/endpoints";
import http from "@/utils/http";
import responseHelper from '@/utils/response-helper';
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_TWOFA_VERIFY,
  AUTH_TWOFA_CONFIRM,
  AUTH_UPDATE_2FA_FLAG,
  AUTH_TWOFA_UPDATE,
  AUTH_CLEAR_TWO_FA,
  AUTH_NAME_UPDATE,
  AUTH_GET_USER_PERMISSION,
  AUTH_UPDATE_USER_PERMISSION
} from "../actions/auth";

const state = {
  status: "",
  hasLoadedOnce: false,
  userId: "",
  userEmail: "",
  twofaSecret: "",
  twofaEnableFlag: false,
  createdAt: "",
  userStatus: "",
  twofaVerify: {},
  role: null,
  userName: '',
  userPermission: [],
  redirectLinks: null,
  isLoginPage: true,
};

const getters = {
  isAuthenticated: state => !!state.userId,
  authStatus: state => state.status,
  userId: state => state.userId,
  userEmail: state => state.userEmail,
  twofaSecret: state => state.twofaSecret,
  twofaEnableFlag: state => state.twofaEnableFlag,
  createdAt: state => state.createdAt,
  userStatus: state => state.userStatus,
  twofaVerify: state => state.twofaVerify,
  role: state => state.role,
  userName: state => state.userName,
  userPermission: state => state.userPermission,
};

const actions = {
  clear2Fa(context) {
    context.commit(AUTH_CLEAR_TWO_FA);
  },

  async updateProfileName({ commit }, val) {
    try {
      let data = {
        name: val.name,
      }
      let res = await http.put(endpoints.updateProfileName, data);
      let names = null;
      if (res.data) {
        names = res.data[0].name;
      }
      console.log(names);
      commit(AUTH_NAME_UPDATE, names);
      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'Name has been updated'
      });
    }
    catch (err) {
      Vue.notify({
        group: "notifications",
        type: "error",
        title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
        text: responseHelper.getErrorMessage(err)
      });
    }
  },
  [AUTH_REQUEST]: (context, data) => {
    return new Promise((resolve, reject) => {
      context.dispatch("clear2Fa").then(() => {
        const params = {
          email: data.email,
          password: data.password,
          "g-recaptcha-response": data["g-recaptcha-response"]
        };
        http
          .post(endpoints.login, params)
          .then(response => {
            if (response.data.twofa) {
              context.commit(AUTH_TWOFA_VERIFY, response.data);
            } else {
              setTimeout(() => {
                context.dispatch(AUTH_GET_USER_PERMISSION, data);
              }, 10);

              context.commit(AUTH_SUCCESS, {
                user: response.data.user,
                redirect: data.redirect
              });
            }

            resolve();
          })
          .catch(err => {
            Vue.notify({
              group: "notifications",
              type: "error",
              title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
              text: responseHelper.getErrorMessage(err),
            });
          });
      });
    });
  },

  [AUTH_TWOFA_CONFIRM]: (context, code) => {
    const data = {
      verify_token: context.state.twofaVerify.verify_token,
      twofa_code: code
    };

    http
      .post(endpoints.confirm2Fa, data)
      .then(response => {
        const redirect = "/admin";

        setTimeout(() => {
          context.dispatch(AUTH_GET_USER_PERMISSION, { redirect });
        }, 10);

        context.commit(AUTH_SUCCESS, {
          user: response.data.user,
          redirect,
        });
      })
      .catch(err => {
        Vue.notify({
          group: "notifications",
          type: "error",
          title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
          text: responseHelper.getErrorMessage(err)
        });
      });
  },

  [AUTH_TWOFA_UPDATE]: (context, params) => {
    return new Promise((resolve, reject) => {
      http
        .post(endpoints.update2Fa, params)
        .then(response => {
          console.log(response);
          context.commit(AUTH_UPDATE_2FA_FLAG, {
            flag: !params.disable
          });
          resolve();
        })
        .catch(err => {
          Vue.notify({
            group: "notifications",
            type: "error",
            title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
            text: responseHelper.getErrorMessage(err)
          });
        });
    });
  },

  [AUTH_GET_USER_PERMISSION]: async (context, authRequestData) => {
    const isAuthenticated = context.getters.isAuthenticated;
    if (!isAuthenticated) {
      return;
    }
    try {
      const params = {
        offset: 0,
        limit: Number.MAX_SAFE_INTEGER,
      }
      const result = await http.get(endpoints.getAuthPermission, { params });
      const items = result.data ? result.data.items : [];
      const data = {
        items,
        redirect: authRequestData ? authRequestData.redirect : ''
      }
      context.commit(AUTH_UPDATE_USER_PERMISSION, data);
    }
    catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Errors',
        text: responseHelper.getErrorMessage(err)
      })
    }
  },

  [AUTH_LOGOUT]: (context, dontRedirectToLoginPage) => {
    http
      .get(endpoints.logout)
      .then(ressponse => {
        context.commit(AUTH_LOGOUT, dontRedirectToLoginPage);
      })
      .catch(err => {
        Vue.notify({
          group: "notifications",
          type: "error",
          title: responseHelper.getMessageByCode('ERROR_COMPONENT_TITLE'),
          text: responseHelper.getErrorMessage(err)
        });
      });
  }
};

const mutations = {
  [AUTH_TWOFA_VERIFY]: (state, args) => {
    state.twofaVerify = { ...args };
  },

  [AUTH_NAME_UPDATE]: (state, userName) => {
    state.userName = userName;
  },

  [AUTH_CLEAR_TWO_FA]: state => {
    state.twofaVerify = {};
  },

  [AUTH_SUCCESS]: (state, { user, redirect }) => {
    state;
    state.status = "success";
    state.userId = user.id;
    state.userEmail = user.email;
    state.userName = user.name;
    state.twofaEnableFlag = user.twofa_enable_flg;
    state.role = user.roles[0];
    state.createdAt = user.create_at;
    state.userStatus = user.user_sts;
    // state.role = user.roles[0].level;
    state.hasLoadedOnce = true;
  },

  [AUTH_LOGOUT]: (state, dontRedirectToLoginPage) => {
    state.status = "";
    state.hasLoadedOnce = false;
    state.userId = "";
    state.userEmail = "";
    state.userName = "";
    state.twofaSecret = "";
    state.twofaEnableFlag = false;
    state.createdAt = "";
    state.userStatus = "";
    state.twofaVerify = {};
    state.userPermission = [];
    state.redirectLinks = null;
    state.isLoginPage = true;

    if (!dontRedirectToLoginPage) {
      router.push('/login');
    }
  },

  [AUTH_UPDATE_2FA_FLAG]: (state, { flag }) => {
    state.twofaEnableFlag = flag;
  },

  [AUTH_UPDATE_USER_PERMISSION]: (state, { items, redirect }) => {
    // Master
    if (state.role && state.role.root_flg) {
      items.push({ name: 'ROOT' });
    }
    state.userPermission = items;

    if (redirect) {
      router.push(redirect);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
