export default {
  /**** Huy code start *** */

  // Childpool
  getPartners: "/web/partners",
  getPartner: id => `/web/partners/${id}`,
  createPartner: "/web/partners",
  updatePartner: id => `/web/partners/${id}`,
  deletePartner: id => `/web/partners/${id}`,

  // API key
  getAPIKeys: id => `/web/partners/${id}/keys`,
  createAPIKey: id => `/web/partners/${id}/keys`,
  deleteAPIKey: (partnerID, id) => `/web/partners/${partnerID}/keys/${id}`,
  //Child
  getGrandChild: id => `/web/partners/${id}/childs`,
  //Memo
  getMemo: id => `/web/partners/${id}/memos`,
  updateMemo: id => `/web/partners/${id}/memos`,
  getMemoHistory: id => `/web/partners/${id}/memos/histories`,
  //Commission
  getCommission: id => `/web/partners/${id}/commissions`,
  updateCommission: id => `/web/partners/${id}/commissions`,
  getCommissionHistory: id => `/web/partners/${id}/commissions/histories`,
  /**** Huy code end *** */

  /**** Son code start *** */
  login: "/web/login",
  logout: "/web/logout",
  forgotPassword: "/web/forgot-password",
  setPassword: "/web/set-new-password",
  getSecret2Fa: "/web/me/2fa",
  update2Fa: "/web/me/2fa",
  confirm2Fa: "/web/confirm-2fa",
  getLoginHistory: "/web/me/login-history",
  changePassword: "/web/me/change-password",
  /**** Son code end *** */
  /**** ThaiBinh code start *** */
  //User
  getUsers: 'web/users',
  deleteUser: id => `web/users/${id}`,
  reSendActiveCode:id=>`web/users/${id}/resend-active-code`,
  //Roles
  getRoles: 'web/roles',
  updateRole:id => `/web/roles/${id}`,
  getPermissionIds: '/web/permissions',
  getRoleHasPermissions:id=> `/web/roles/${id}/permissions`,
  //Staking Platform
  getStakingPlatforms:'/web/staking-platforms',
  updateStakingPlatform:id=>`/web/staking-platforms/${id}`,
  getStakingPlatformTimeUnit:'/web/staking-platforms/time-unit',
  getStakingPlatformConfig:'/web/staking-platforms/config',
  activeUser:'/web/active-user',
  checkToken:token=>`/web/check-token/${token}`,
  /**** ThaiBinh code end *** */
  /**** Tri Binh code start *** */
  createUser: "/web/users",
  getUser: id => `/web/users/${id}`,
  updateUser: id => `/web/users/${id}`,
  resendOtp: '/web/users/resend-verify-email',
  getProfile: '/web/me',
  getSettingCommission: '/web/settings/commissions',
  getSettingCommissionHistory: '/web/settings/commissions/histories',
  getRewardSetting: '/web/settings/rewards',
  getRewardSettingHistory: '/web/settings/rewards/histories',
  getComDistribute: `/web/settings/commissions`,
  getComDistributeHistory: `/web/settings/commissions/histories`,
  updateComDistribute: `/web/settings/commissions`,
  getColdWallet:'/web/cold-wallets',
  updateColdWallet: '/web/cold-wallets',
  /**** Tri Binh code end *** */
  // AnhND code start
  getItemsStakingLst: id => `/web/partners/${id}/stats`,
  getItemsCommissionLst: id => `/web/partners/${id}/stats/commissions`,
  // AnhND code end
  //Payout
    getPayout: '/web/payouts',
  //ERC20 platform
  updateerc20:id=>`/web/staking-platforms/erc20/${id}`,
  addERC20: '/web/staking-platforms/erc20',
    //Staking plan
  AddStakingPlan: platform_id => `/web/staking-platforms/${platform_id}/plans`,
  getStakingPlan: id => `/web/staking-platforms/${id}/plans`,
  getStakingPayout: id => `/web/staking-platforms/${id}/payouts`,
  updatePayoutmax: (stakingId, payoutId) => `/web/staking-platforms/${stakingId}/payouts/${payoutId}`,
  updateStakingPlan: (stakingId, plan_id) => `/web/staking-platforms/${stakingId}/plans/${plan_id}`,
  //thiennv code start
  addRoles:`/web/roles`,
  updatenative:id=> `/web/staking-platforms/${id}`,
  updateProfileName: `/web/me/profile`,
  getSettings: '/web/settings',
  getAllRequestChangeReward:id => `/web/partners/${id}/requests`,
  confirmCommissionRequest:(partner_id,commission_id,id) => `/web/partners/${partner_id}/commissions/${commission_id}/requests/${id}`,
  getAuthPermission: '/web/me/permissions',
  getRoleHasPermission: '/web/roles-have-permission',
  resendConfirmCommissionRequest:(partner_id,commission_id,id) => `/web/partners/${partner_id}/commissions/${commission_id}/requests/${id}/resend-email`,
  // thiennv code end

  //get Reward
  getRewards: '/web/total-rewards',

  // get revenue
  getRevenue: '/web/revenue',
  downloadCsv: '/web/revenue/csv'
};
